import React, { useState } from 'react';

import ReactCardFlip from 'react-card-flip';
import Grid from '@material-ui/core/Grid';

export default function WorkChunk(props) {
  const [isFlipped, changeFlip] = useState(false);

  return(
    <div class="chunkCompOuter">

      <div class="desktop">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
          <div class={"chunkEntry " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
            <Grid container>
              {/*Work entry 1*/}
              <Grid item xs={2}> <img src={require('./../media/' + props.img + '.png')} /> </Grid>

              <Grid item xs={10}>
                <div class="entries">
                  <Grid container>
                    <Grid item xs={6}>
                      Company: <b>{props.company}</b>
                    </Grid>
                    <Grid item xs={6}>
                      Location: <b>{props.location}</b>
                    </Grid>
                    <Grid item xs={6}>
                      Position: <b>{props.position}</b>
                    </Grid>
                    <Grid item xs={6}>
                      Level: <b>{props.level}</b>
                    </Grid>
                    <Grid item xs={6}>
                      Dates: <b>{props.date}</b>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>

          <div class={"chunkEntry " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
            <Grid container>
              {/*Work entry 1*/}
              <Grid item xs={2}> <img src={require('./../media/' + props.img + '.png')} /> </Grid>

              <Grid item xs={10}>
                <div class="entries jobDesc">

                  {props.desc}

                </div>
              </Grid>
            </Grid>
          </div>
        </ReactCardFlip>
      </div>

      <div class="mobile">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <div class={"chunkEntry chunkPic gmPic marBot noPadd picPadd " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
              <img src={require('./../media/' + props.img + '.png')} />
              {props.company}
          </div>

          <div class={"chunkEntry gmPic noPadd descPadd " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
                <div class="entries">
                    <b>Company:</b> {props.company}
                    <Grid container>
                      <Grid item xs={6}><b>Position:</b> {props.position}</Grid>
                      <Grid item xs={6}><b>Location:</b> {props.location}</Grid>
                      <Grid item xs={6}><b>Level:</b> {props.level}</Grid>
                      <Grid item xs={6}><b>Dates:</b> {props.date}</Grid>
                      <Grid item xs={12}><b>Description:</b> {props.desc}</Grid>
                    </Grid>
                </div>
          </div>
        </ReactCardFlip>
      </div>

    </div>
  );
}
