import React, { useState } from 'react';

import ReactCardFlip from 'react-card-flip';
import Grid from '@material-ui/core/Grid';

export default function SchoolChunk(props) {
  const [isFlipped, changeFlip] = useState(false);

  return(
    <div class="chunkCompOuter">

      <div class="desktop">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
          <div class={"chunkEntry " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
            <Grid container>
              {/*Work entry 1*/}
              <Grid item xs={2}> <img src={require('./../media/' + props.img + '.png')} /> </Grid>

              <Grid item xs={10}>
                <div class="entries">
                  <Grid container>
                    <Grid item xs={6}>
                      University: <b>{props.school}</b>
                    </Grid>
                    <Grid item xs={6}>
                      Degree: <b>{props.degree}</b>
                    </Grid>
                    {
                      props.major
                        ?
                        <Grid item xs={6}>
                          Major: <b>{props.major}</b>
                        </Grid>
                        :
                        <Grid item xs={6}>
                          Location: <b>{props.location}</b>
                        </Grid>
                    }
                    <Grid item xs={6}>
                      Attended: <b>{props.date}</b>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>

          <div class={"chunkEntry " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
            <Grid container>
              {/*Work entry 1*/}
              <Grid item xs={2}> <img src={require('./../media/' + props.img + '.png')} /> </Grid>

              <Grid item xs={10}>
                <div class="entries">
                  {props.desc}{props.link}
                </div>
              </Grid>
            </Grid>
          </div>
        </ReactCardFlip>
      </div>

      <div class="mobile">
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <div class={"chunkEntry chunkPic " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
              <img src={require('./../media/' + props.img + '.png')} />
              {props.shortSchool}
          </div>

          <div class={"chunkEntry " + props.color + "Chunk"} onClick={() => changeFlip(!isFlipped)}>
            <Grid container>
              <Grid item xs={12}>
                <div class="entries">
                  <b>University:</b> {props.school}<br/>
                  <b>Attended Dates:</b> {props.date}<br/>
                  <b>Degree:</b> {props.degree}<br/>

                  {
                    props.major
                      ?
                      <span><b>Major:</b> {props.major}<br/></span>
                      :
                      <span><b>Location:</b> {props.location}<br/></span>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
        </ReactCardFlip>
      </div>

    </div>
  );
}
