import React, { Component } from 'react';
import './App.css';

import WorkChunk from './components/workChunk';
import SchoolChunk from './components/schoolChunk';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';


// Skills Logo Imports
import RustLogo from './media/skills/rust.png';
import CSharpLogo from './media/skills/csharp.png';
import AndroidLogo from './media/skills/android.png'
import HadoopLogo from './media/skills/hadoop.png';
import MongoDBLogo from './media/skills/mongodb.png';
import JavaLogo from './media/skills/java.png';
import PythonLogo from './media/skills/python.png';
import MySQLLogo from './media/skills/mysql.png';
import NodeJSLogo from './media/skills/nodejs.png';
import JavaScriptLogo from './media/skills/javascript.png';
import ReactJSLogo from './media/skills/reactjs.png';
import CSSHTMLLogo from './media/skills/csshtml.png';
import NoSQLLogo from './media/skills/nosql.png';
import UnrealEngineLogo from './media/skills/unrealengine.png';
import CppLogo from './media/skills/c++.png';
import SparkLogo from './media/skills/spark.png';

// Work & School Logos
import GMLogo from       './media/GM-logo.png';
import UCRLogo from      './media/UCR-logo1.png';
import CHSLogo from      './media/CHS-logo.png';
import ProfilePic from   './media/GM-Profile-Pic.png';
import IQHILogo from     './media/IQHI-logo.png';
import HyperionLogo from './media/Hyperion-logo.png';
import MobilionLogo from './media/Mobilion-logo.png';

import Unmasked from './media/unmasked.png';
import Masked from './media/masked.png';

// import UELogo from        './media/UE-logo.png';
// import ArduinoLogo from   './media/Arduino-logo.png';
// import LeapLogo from      './media/Leap-logo.png';

import ReactCardFlip from 'react-card-flip';
import Tilt from 'react-tilt';

import { FaBars, FaGithub, FaLinkedin, FaScroll, FaEnvelopeOpenText, FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa';


var self;

class App extends Component {
  constructor(props) {
    super(props);

    self = this;
    self.state = {
      gmFlip: false,
      iqhiFlip: false,
      hyperionFlip: false,
      mobilionFlip: false,
      ucrFlip: false,
      chsFlip: false,
      ronaFlip: false,
      tileExpand: [0,0,0,0],
      navExpand: false,
      contactClick: false,
    }
  }

  gmClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ gmFlip: !prevState.gmFlip }));
  }

  iqhiClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ iqhiFlip: !prevState.iqhiFlip }));
  }

  hyperionClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ hyperionFlip: !prevState.hyperionFlip }));
  }

  mobilionClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ mobilionFlip: !prevState.mobilionFlip }));
  }

  ucrClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ ucrFlip: !prevState.ucrFlip }));
  }

  chsClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ chsFlip: !prevState.chsFlip }));
  }

  ronaClick(e) {
    e.preventDefault();
    self.setState(prevState => ({ ronaFlip: !prevState.ronaFlip }));
  }

  tileClick(i) {

    // alert('Current state of tile: ' + self.state.tileExpand[i]);

    if(self.state.tileExpand[i] === 0) {
      document.getElementsByClassName("thinTile")[i].style.height = '100%';

      let tempArr = self.state.tileExpand; console.log(tempArr)
      tempArr[i] = tempArr[i] + 1;
      self.setState({ tileExpand: tempArr})

    } else {
      document.getElementsByClassName("thinTile")[i].style.height = '100px';

      let tempArr = self.state.tileExpand; console.log(tempArr)
      tempArr[i] = tempArr[i] -1;
      self.setState({ tileExpand: tempArr})
    }


    // alert('After click ' + self.state.tileExpand);

  }

  hideSmallTiles() {

    let tiles = document.getElementsByClassName("thinTile");

    for(let tile = 0; tile < tiles.length; tile++) {
      // alert('Setting tile#' + tile)
      tiles[tile].style.opacity = '10%';
    }

  }

  showSmallTiles() {

    let tiles = document.getElementsByClassName("thinTile");

    for(let tile = 0; tile < tiles.length; tile++) {
      // alert('Setting tile#' + tile)
      tiles[tile].style.opacity = '100%';
    }

  }

  goto(destination) {
    const anchor = document.querySelector(destination);
    anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  toggleNav(e) {
    e.preventDefault();

    self.setState(prevState => ({ navExpand: !prevState.navExpand }));
  }

  toggleContact(e) {
    e.preventDefault();

    self.setState(prevState => ({ contactClick: !prevState.contactClick }) );
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    return (
      <div className="App">
        <div class="topBar">

          <div class="desktop">
            <Grid container>
              <Grid item xs={2}><div class="navItem" onClick={() => self.goto("#HOME")}>Hamza Awad</div></Grid>
              <Grid item xs={2}><div class="navItem" onClick={() => self.goto("#EE")}>Education & Experience</div></Grid>
              <Grid item xs={2}><div class="navItem" onClick={() => self.goto("#PROJECTS")}>Projects</div></Grid>
              <Grid item xs={2}><div class="navItem" onClick={() => self.goto("#SKILLS")}>Skills</div></Grid>
              <Grid item xs={2}><div class="navItem" onClick={self.toggleContact}>Contact</div></Grid>
              <Grid item xs={2}><div class="navItem"><a href="https://docs.google.com/document/d/15sYZmJEzhZTBl4l3ETp4sL8EUU4eTFMGgpo3kZ7iwL8/export?format=pdf" target="_blank">Download Resume</a></div></Grid>
            </Grid>
          </div>
          <div class="mobile">
            <Grid container>
              <Grid item xs={4}>
                <div class="mobileNav">
                  <div class="hamburger" onClick={self.toggleNav}>
                    <FaBars size={28}/>
                  </div>
                  <div class="buns">
                    {
                      self.state.navExpand
                        ?
                          <div class="ingredients">
                            <div class="navItem" onClick={() => self.goto("#HOME")}>    <span class="unselectable">Hamza Awad</span></div>
                            <div class="navItem" onClick={() => self.goto("#EE")}>      <span class="unselectable">Education & Experience</span></div>
                            <div class="navItem" onClick={() => self.goto("#PROJECTS")}><span class="unselectable">Projects</span></div>
                            <div class="navItem" onClick={() => self.goto("#SKILLS")}>  <span class="unselectable">Skills</span></div>
                            <div class="navItem" onClick={self.toggleContact}>Contact</div>
                            <div class="navItem"><a href="https://docs.google.com/document/d/12sMs1bDEpT4lknQCC9LHGCDVqvf53kpwm--retvPUN0/export?format=pdf">Download Resume</a></div>
                          </div>
                        :
                          <span></span>
                    }
                  </div>
                </div>
              </Grid>

              <Grid item xs={8}>
                <div class="navItem mNav" onClick={() => self.goto("#HOME")} style={{paddingRight: '30px'}}>Hamza Awad</div>
              </Grid>
            </Grid>

            <Dialog onClose={self.toggleContact} aria-labelledby="simple-dialog-title" open={self.state.contactClick}>
              <div class="dialogue">
                You can reach out to me by emailing me at: <i>hamza17awad@gmail</i>
              </div>
            </Dialog>

          </div>

        </div>

        <div class="Body">

          <div class="section Landing blue" id="HOME">

            <div class="title intro">
              <Grid container>
                <Grid item xs={4}>
                  <div class="unselectable"><img src={ProfilePic} /></div>
                </Grid>
                <Grid item xs={8}>
                  <span class="desktop"><br/><b>Hey! I'm Hamza Awad.</b><br/>
                    <span id="smallerTitle">Welcome to my site!
                    <div class="iconsHolder desktop">
                      <a href="https://www.github.com/awadhamza" target="_blank"><FaGithub /></a>
                      <a href="https://www.linkedin.com/in/awadhamza" target="_blank"><FaLinkedin /></a>
                      <a href="https://docs.google.com/document/d/15sYZmJEzhZTBl4l3ETp4sL8EUU4eTFMGgpo3kZ7iwL8/preview" target="_blank"><FaScroll /></a>
                    </div>
                    </span>
                  </span>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={9}>

                </Grid>
                <Grid item xs={3}>
                  <div class="smallText desktop">
                    Last updated: 10/03/2022
                  </div>
                </Grid>
              </Grid>

            </div>

            <div id="EE" />

          </div>

          {/* <div class="section black">
            <div class="title mTitle marBot unselectable mobile">About me</div>
            <div class="title marBot unselectable desktop">About me</div>

            <div class="body_desc">
              <div class="desc_header">
                Background
              </div>
              Growing up, I was told that I had no other career choice except becoming a medical doctor. After being exposed to the realm of coding and software development at the end of my first year in university, my enthusiasm and excitement lead me to begin taking courses in Computer Science and, thus, eventually change my major.
              <br/><br/>
              Ever since then, I haven't looked back! I have experienced many different facets of software engineering such as algorithms, data structures, relational database management systems, operating systems, compiler design, artificial intelligence, computer architecture, and web application development.
              <br/><br/>
              While I enjoy a good number of those, I always find myself enjoying web development the most. I have started a number of ambitious passion projects during my undergrad, and continue on those till this day.
              <br/><br/>
              <div class="desc_header">
                Passion Project: UCRGarden.com
              </div>
              One such passion project is for my university's community garden, R'Garden. This garden doesn't get too much student volunteers or as much grants and support as I thought it deserved, so I proposed a new and official website dedicated to garnering student activity at the R'Garden.
              <br/><br/>
              This website has many cool features, at different stages of development. Student's can "check in" to the garden via the website's use of geolocation, to avoid any student from checking in from the comfort of their bed 😂. Additionally, there currently is a basic yet functioning chat forum as part of the website that uses a realtime database to support instant updates to all chat facing clients. First time users need to create an account, and then have access to different categories and channels for which to communicate in.
              <br/><br/>
              In the future, I hope to add functionalities for tracking gardener plots, such as plot reservations and watering history. Also, an event tracker that notifies users about upcoming events and users can comment and create an intent to participate!
              <br/><br/>
              <div class="desc_header">
                Work Experiences
              </div>
            </div>

          </div> */}

          <div class="section black">

            <div class="title mTitle marBot unselectable mobile">Education & Experience<div class="smallText marTop">(Click on tiles to learn more)</div></div>
            <div class="title marBot unselectable desktop">Education & Experience<div class="smallText marTop">(Click on tiles to learn more)</div></div>
            <Grid container>

              {/*EDUCATION*/}
              <Grid item xs={12} sm={6}>
                <div class="chunkTitle unselectable">Latest Education:</div>
                  {/*UCR*/}
                <SchoolChunk type='uni' shortSchool='UC Riverside' school='University of California, Riverside' college='Bourns College of Engineering' date='Sept 2015 - June 2020' degree="Bachelor's of Science" major='Computer Science' color='yellow' img='UCR-logo1' desc="Began my bachelors in Biology, then completely switched to CS after discovering coding!"/>

                  {/*CHS*/}
                <SchoolChunk type='hs' shortSchool='Cypress HS' school='Cypress High School' college='' date='August 2011 - June 2015' degree="HS Diploma" major='' color='orange' img='CHS-logo' location='Cypress, California' desc={"I got 1st place in a 800m race and I ended up on the "} link={<a target='_blank' href='https://www.google.com/search?q=hamza+award+cypress+track&tbm=isch&ved=2ahUKEwiRjfzqrsL6AhURYVMKHSX7CjYQ2-cCegQIABAA&oq=hamza+award+cypress+track&gs_lcp=CgNpbWcQA1CwBFiwBGCYB2gAcAB4AIABV4gBnAGSAQEymAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=CPI5Y5HpEZHCzQKl9quwAw&bih=1215&biw=2271&rlz=1C1CHBF_enUS972US972#imgrc=WcQXWAPWSnsGMM'>news (see the first picture)</a>}/>
                
              </Grid>

              {/*EXPERIENCE*/}
              <Grid item xs={12} sm={6}>

                  <div class="chunkTitle unselectable">Current Work:</div>
                    {/*GM Job*/}
                  <WorkChunk color='blue' img='GM-logo' company='General Motors' position='Software Developer' level='Entry (5)' date='Nov 2020 - Present' location='Austin, Texas' desc='Worked on a suite of customer facing applications including desktop and web applications. Worked full stack across each application, debugged into large code bases, and participated in design conversations. Learned new and required technology and frameworks to maximize contribution and productivity.' />

                  <div class="chunkTitle unselectable">Past Work:</div>
                    {/*IQHi Job*/}
                  <WorkChunk color='silver' img='IQHI-logo' company='IQHi Battery Inc.' position='Full Stack Developer' level='Intern' date='June 2020 - July 2020' location='Irvine, California' desc='I was part of the web developer team where my role was to complete a set of front end designs and functionalities in time for presentation to potential customers. I was also responsible for presenting our application directly to company partners.' />

                    {/*Hyperion Job*/}
                  <WorkChunk color='black' img={'Hyperion-logo'} company='Hyperion Motors' position='Software Engineer' level='Contract' date='June 2019 - Aug 2019' location='Orange, California' desc='I made multiple proprietary, educational video games for foreign investors to interact with and learn about our company mission and work-in-progress technology. See resume for details.'/>

                    {/*Mobilion Job*/}
                  <WorkChunk color='pink' img={'Mobilion-logo'} company='Mobilion Bilişim' position='Android Application Developer' level='Intern' date='July 2018 - Aug 2018' location='Istanbul, Turkey' desc='I developed a comprehensive Android application which covered fundamental concepts of storage, permissions, layouts, filtering, and Google APIs.'/>

              </Grid>

            </Grid>

            <div id="PROJECTS" />

          </div>

          <div class="section black3">
            <div class="title marBot unselectable">Projects<div class="smallText marTop">(Click the titles of each box to find out more)</div></div>

            <div class="tiltHolders">
            <Grid container>

              <Grid item xs={12} sm={7}>
                <Tilt className="Tilt"
                  options={{ max : 15 }}
                  style={{  }}
                  onMouseEnter={self.hideSmallTiles}
                  onMouseLeave={self.showSmallTiles}
                >
                  <div className="Tilt-inner">

                    <ReactCardFlip isFlipped={self.state.ronaFlip} flipDirection="horizontal">
                      <div class="projects bigTile">
                        <a class="tileTitle" href="https://github.com/ucr-cs179j-team02/CS179J-Team02" target="_blank">RonaZona</a> <span class="pIcon" onClick={self.ronaClick}><FaArrowCircleRight /></span>
                        <div class="line" />
                        <div class="contents">
                          <Grid container>
                            <Grid item xs={6}>
                              <div class="projectImgs">
                                <img src={Masked} />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div class="projectImgs">
                                <img src={Unmasked} />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>

                      <div class="projects bigTile" id="smallerTitle" onClick={self.ronaClick}>
                        RonaZona <span class="pIcon"><FaArrowCircleLeft size={64} /></span>
                        <div class="line" />
                        <div class="contents medText">
                          <Grid container>
                            <Grid item xs={12}>
                              • Senior Design Embedded Systems Project <br/><br/>
                              • Responsible for producing a Computer Vision model that detects between MASKED and UNMASKED persons within device proximity <br/><br/>
                              • Promoted team communication and agile habits through project development <br/><br/>
                              • Doubled the successful UNMASKED persons detection rate by customizing the object detection model's training configuration <br/><br/>
                              • Technologies used: Raspberry Pi 3, TensorFlow, Python
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </ReactCardFlip>

                  </div>
                </Tilt>
              </Grid>

              <Grid item  xs={12} md={5}>

                {/*Duck Photography*/}
                <Tilt className="Tilt" options={{ max : 10, scale: 1.07 }} style={{  }} >
                  <div className="Tilt-inner" onClick={() => self.tileClick(0)}>
                    <div class="projects thinTile">
                      <a href="https://www.youtube.com/watch?v=eGE1IXBL2o4" target="_blank" class="tileTitle">Duck Hunt Spin-Off</a>
                      <div class="line"/>
                      <span class="medText unselectable">
                        (An Embedded Systems Project)
                        <br/><br/>
                          Created a single and multiplayer game on a breadboard involving an ATMEGA microcontroller, an LED matrix, and shift registers.
                      </span>
                    </div>
                  </div>
                </Tilt>

                {/*AI*/}
                <Tilt className="Tilt" options={{ max : 10, scale: 1.07 }} style={{  }} >
                  <div className="Tilt-inner" onClick={() => self.tileClick(1)}>
                    <div class="projects thinTile">
                      <a href="https://github.com/awadhamza/Tile-Puzzle-Solving-AI" target="_blank" class="tileTitle">3x3 Tile Puzzle Solver</a>
                      <div class="line"/>
                      <span class="medText unselectable">
                        (An Artificial Intelligence Project)
                        <br/><br/>
                          Built an AI program which solves any valid 3x3 tile puzzle and provides 1) options for solution approaches and 2) algorithm analysis.
                      </span>
                    </div>
                  </div>
                </Tilt>

                {/*WEB*/}
                <Tilt className="Tilt" options={{ max : 10, scale: 1.07 }} style={{  }} >
                  <div className="Tilt-inner" onClick={() => self.tileClick(2)}>
                    <div class="projects thinTile">
                      <a href="https://github.com/awadhamza/Team-626-Keep" target="_blank" class="tileTitle">
                        Google Keep Replica
                      </a>
                      <div class="line"/>
                      <span class="medText unselectable">
                        (A Full Stack Web App Project)
                        <br/><br/>
                          Created a web app that uses account authentication, Firebase for our backend and React.js for our frontend, image uploading, and note sharing.
                      </span>
                    </div>
                  </div>
                </Tilt>

                {/*RSHELL*/}
                <Tilt className="Tilt" options={{ max : 10, scale: 1.07 }} style={{  }} >
                  <div className="Tilt-inner" onClick={() => self.tileClick(3)}>
                    <div class="projects thinTile">
                      <a href="https://github.com/awadhamza/R-Shell" target="_blank" class="tileTitle">
                        R'shell
                      </a>
                      <div class="line"/>
                      <span class="medText unselectable">
                        (A Command Line Project)
                        <br/><br/>
                          Developed a command line application which simulates a bash environment and utilizes command chaining, precedence, grouping, and I/O redirection.
                      </span>

                    </div>
                  </div>
                </Tilt>
              </Grid>
            </Grid>

            </div>

            <div class="bigMarBot" />
            <div id="SKILLS" />
          </div>

          <div class="section black2">
            <div class="title marBot">Skills</div>
            <div class="tableHolder">
              <table>
                <thead>
                <tr>
                  <th>Used It Before</th>
                  <th>Proficient</th>
                  <th>My Jam</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><img src={CppLogo} />C++</td>
                  <td><img src={NoSQLLogo} />NoSQL</td>
                  <td><img src={JavaScriptLogo} />JavaScript</td>
                </tr>
                <tr>
                  <td><img src={MongoDBLogo} />MongoDB</td>
                  <td><img src={UnrealEngineLogo} />Unreal Engine</td>
                  <td><img src={ReactJSLogo} />React.js</td>
                </tr>
                <tr>
                  <td><img src={SparkLogo} />Spark</td>
                  <td><img src={MySQLLogo} />MySQL</td>
                  <td><img src={CSSHTMLLogo} />CSS/HTML</td>
                </tr>
                <tr>
                  <td><img src={HadoopLogo} />Hadoop</td>
                  <td><img src={NodeJSLogo} />NodeJS</td>
                  <td><img src={JavaLogo} />Java</td>
                </tr>
                <tr>
                  <td><img src={AndroidLogo} />Android</td>
                  <td></td>
                  <td><img src={PythonLogo} />Python</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default App;
